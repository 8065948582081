<template>
  <div class="category-index-setting">
    <div class="header pb-3">
      <div class="style-title-setting-global">
        {{
          $i18n.locale == 'fr'
            ? 'Gestion des Catégories'
            : 'Category Management'
        }}
      </div>
      <div v-if="getCategorieLoading" class="loading">
        {{ $t('loading') }}
      </div>
      <div v-if="getCategorieError" class="error-msg">
        <ul v-if="Array.isArray(getCategorieError)">
          <li v-for="(e, index) in getCategorieError" :key="index">
            {{ e }}
          </li>
        </ul>
        <span v-else>{{ getCategorieError }}</span>
      </div>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark color="#5C2DD3" v-bind="attrs" v-on="on">
            <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
            {{
              $i18n.locale == 'fr' ? 'Ajouter une catégorie' : 'Add category'
            }}
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(item, index) in getCustomFieldsMenu"
            :key="index"
            link
          >
            <v-list-item-content
              @click.prevent.stop="Drapdownbutton(item)"
              :disabled="getCustomFieldsLoading"
            >
              <v-list-item-title>{{
                item.name
                  ? $options.filters.translateName(item.name, $i18n.locale)
                  : item.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="block-menu-users mt-2">
      <div class="list-tabs style-tabs">
        <v-tabs
          bg-color="#5C2DD3"
          :hide-slider="false"
          slider-color="#5C2DD3"
          color="#5C2DD3"
          item-color="#5C2DD3"
          v-model="model"
        >
          <v-tab
            href="#tab-0"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('contacts')"
            to="/setting/categorie/contacts"
          >
            <div class="menu-item">
              {{ $i18n.locale == 'fr' ? 'Contacts' : 'Contacts' }}
            </div>
          </v-tab>
          <v-tab
            href="#tab-1"
            color="#5C2DD3"
            item-color="#5C2DD3"
            @click="clickMenu('projets')"
            to="/setting/categorie/projets"
          >
            <div class="menu-item">
              {{ $i18n.locale == 'fr' ? 'Projets' : 'Projects' }}
            </div>
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
    <!-- ADD_CUSTOM_FIELDS -->
    <v-dialog v-model="addCategorieModal" max-width="900" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              selectedlinkButton && selectedlinkButton.name === 'projets'
                ? $i18n.locale == 'fr'
                  ? 'Ajouter une catégorie projet'
                  : 'Add project category'
                : $i18n.locale == 'fr'
                ? 'Ajouter une catégorie contact'
                : 'Add contact category'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addCategorieModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addCategorieModal" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  v-model="categorieToAdd.name"
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  multiple
                  :persistent-placeholder="true"
                  chips
                  return-object
                  :deletable-chips="true"
                  :small-chips="true"
                  dense
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  :label="$t('typeProjet')"
                  :placeholder="$t('searchMsg', { msg: $t('typeProjet') })"
                  v-model="categorieToAdd.dtypes"
                  :no-data-text="
                    $t('noDataOptionMsg', { option: $t('typeProjet') })
                  "
                  item-text="name"
                  item-value="name"
                  :items="getProjectsTypes"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getCategorieLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getCategorieError" class="error-msg">
              <ul v-if="Array.isArray(getCategorieError)">
                <li v-for="(e, index) in getCategorieError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getCategorieError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmit"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addCategorieModal')">
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Categorie Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import { Container, Draggable } from 'vue-smooth-dnd'
export default {
  data() {
    return {
      model: 'tab-0',
      categorieToAdd: {
        name: null,
        dtypes: []
      },
      customizable: 1,
      selectedlinkButton: null,
      selectedMenu: 'contacts',
      id_contacts: null,
      id_projects: null,
      addCategorieModal: false,
      loading: false
    }
  },

  methods: {
    ...mapActions([
      'addCategoriesCategorie',
      'resetErrorCategorie',
      'fetchCategoriesConteacts',
      'fetchCategoriesProjects',
      'fetchAllTablesCustomFields'
    ]),
    async clickMenu(menu) {
      this.selectedMenu = menu
      if (this.selectedMenu === 'contacts') {
        await this.fetchCategoriesConteacts(this.id_contacts.id)
      }
      if (this.selectedMenu === 'projets') {
        await this.fetchCategoriesProjects(this.id_projects.id)
      }
    },
    Drapdownbutton(linkButton) {
      this.addCategorieModal = true
      this.selectedlinkButton = linkButton
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addCategorieModal') {
        this.$refs.addCategorieModal.resetValidation()
      }
      this.resetModal()
    },
    resetModal() {
      this.categorieToAdd = {
        name: null,
        dtypes: []
      }
      this.resetErrorCategorie()
    },
    async handleSubmit() {
      if (this.$refs.addCategorieModal.validate()) {
        this.loading = true
        const response = await this.addCategoriesCategorie({
          categorie: this.categorieToAdd,
          table_id: this.selectedlinkButton
        })
        if (response) {
          this.closeDialog('addCategorieModal')
        }
        this.loading = false
      }
    }
  },

  computed: {
    ...mapGetters([
      'getCategorieLoading',
      'getCategorieError',
      'getCustomFieldsMenu',
      'getCustomFieldsLoading',
      'getProjectsTypes'
    ])
  },
  filters: {
    translateName: function(name, locale) {
      switch (name) {
        case 'projets':
          return locale === 'fr' ? 'Projets' : 'Projects'
        case 'contacts':
          return locale === 'fr' ? 'Contacts' : 'Contacts'
        case 'type_ressources':
          return locale === 'fr' ? 'Types ressources' : 'Resource type'
        case 'type_events':
          return locale === 'fr' ? 'Types événements' : 'Event type'
      }
      return ''
    }
  },
  async mounted() {
    await this.fetchAllTablesCustomFields({
      customizable: this.customizable
    })
    this.id_contacts = this.getCustomFieldsMenu.find(
      item => item.name === 'contacts'
    )
    this.id_projects = this.getCustomFieldsMenu.find(
      item => item.name === 'projets'
    )
    if (this.$route && this.$route.name == 'categorie-contacts') {
      this.fetchCategoriesConteacts(this.id_contacts.id)
    } else {
      this.fetchCategoriesProjects(this.id_projects.id)
    }
  }
}
</script>

<style lang="scss">
.category-index-setting {
  .col-ss-categ {
    padding: 4px 12px !important;
  }
}
</style>
<style lang="scss" scoped>
.category-index-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .block-menu-users {
    .list-tabs {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }
    .v-tab {
      &:hover {
        text-decoration: none;
      }
    }
    v-tab .v-tab--active {
      &:hover {
        text-decoration: none;
      }
    }
    .menu-item {
      &.router-link-exact-active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .v-tab {
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.4px;
      font-family: 'Montserrat', sans-serif;
      &.v-tab--active {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        color: #5c2dd3;
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
      &.v-tab--disabled {
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.4px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
}
</style>
